<template>
  <div>
    <h4>Motif Decès</h4>
    
    <data-table
      :data-source="sortedMotifDeces"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          @click.prevent="initForm"
          data-toggle="modal"
          data-target="#add-motif-deces"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
    <modal
      title="Ajout d'un motif de décès"
      id="add-motif-deces"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="addingEspece"
      >
        <div class="form-group">
          <label for="add-libelle">Libelle</label>
          <input
            type="text"
            id="add-libelle"
            name="add-libelle"
            v-model="libelle"
            class="form-control"
          >
        </div>

        <div class="form-group">
          <label for="add-code">Code</label>
          <input
            type="text"
            id="add-code"
            name="add-code"
            v-model="code"
            class="form-control"
          >
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Ajouter
            </button>
          </div>
        </div>
      </form>
    </modal>
    <modal
      title="Modification de l'espèce"
      id="update-motif-deces"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="editingEspece"
      >
        <div class="form-group">
          <label for="edit-libelle">Libelle</label>
          <input
            type="text"
            id="edit-libelle"
            name="edit-libelle"
            v-model="libelle"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <label for="edit-code">Code</label>
          <input
            type="text"
            id="edit-code"
            name="edit-code"
            v-model="code"
            class="form-control"
          >
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import {ADD_MOTIF_DECES, EDIT_MOTIF_DECES } from '../../../graphql/identification'
import DataTable from '../../../components/dataTable/local.vue'
import {TEXT_TYPE, COMPONENT_TYPE } from '../../../components/dataTable/dataType'
import Modal from '../../../components/modal.vue'
const Actions = () => import('../../../components/admin/identification/motifDecesAction.vue')
export default {
    components: { DataTable, Modal},
    data(){
        return {
            libelle: null,
            code: null,
            has_error: false,
            error_msg: null
        }
    },
    watch: {
      selectedObject: {
            handler(){
                if(this.selectedObject !== null) {
                    this.libelle = this.selectedObject.libelle
                    this.code = this.selectedObject.code
                }
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            done: 'DONE',
            setSelectedObject: 'SET_SELECTED_OBJECT'
        }),
        initForm(){
            this.setSelectedObject(null)
            this.libelle = null
            this.code = null
        },
        addingEspece(){
            let data = {
                libelle: this.libelle,
                code: this.code
            }
            this.$apollo.mutate({
                mutation: ADD_MOTIF_DECES,
                variables: {
                    "motif": {
                        ...data
                    }
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Alimentation add successfully on uid ${d.addEspece}`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        },
        editingEspece(){
            let data = {
                libelle: this.libelle,
                code: this.code
            }
            this.$apollo.mutate({
                mutation: EDIT_MOTIF_DECES,
                variables: {
                    "motif": {
                        ...data
                    }, 
                    "uid": this.selectedObject.uid
                },
                update: () => {
                    console.log(`Alimentation  ${this.selectedObject.libelle} edit successfully on uid ${this.selectedObject.uid}`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
            motifDeces: 'identification/motifDeces',
            selectedObject: 'selectedObject'
        }),
        sortedMotifDeces(){
          return [...this.motifDeces].sort((a,b) => a.libelle.localeCompare(b.libelle))
        },
        headers(){
            return [
                {label: 'ID', name: 'uid', type: TEXT_TYPE},
                {label: 'Libelle', name: 'libelle', type: TEXT_TYPE},
                {label: 'Code', name: 'code', type: TEXT_TYPE},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions},
            ]
        }
    }
}
</script>

<style>

</style>